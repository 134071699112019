import { Switch } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";

import { toggleDarkMode } from "../../store/slices/darkmodeSlice";

import moon from "../../asserts/images/moon-night-svgrepo-com.svg";
import sun from "../../asserts/images/sun-summer-svgrepo-com.svg";

const ToggleSwitch = (props) => {
  const dispatch = useDispatch();

  const darkMode = useSelector((state) => state.darkMode.darkMode);

  return (
    <Switch
      checked={darkMode}
      onChange={() => dispatch(toggleDarkMode())}
      className={`relative inline-flex items-center h-6 rounded-full w-11 ${
        darkMode ? "bg-black" : "bg-white"
      }`}
    >
      <span className="absolute left-0 top-1/2 transform -translate-y-1/2 h-4 w-4 hidden dark:block">
        <img src={moon} alt="" />
      </span>
      <span className="absolute right-1 top-1/2 transform -translate-y-1/2 h-4 w-4 dark:hidden">
        <img src={sun} alt="" />
      </span>
      <span className="sr-only">Enable Dark Mode</span>
      <span
        className={`inline-block w-4 h-4 transform bg-black dark:bg-white rounded-full ${
          darkMode ? "translate-x-6" : "translate-x-1"
        }`}
      />
    </Switch>
  );
};

export default ToggleSwitch;
