import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ImCross } from "react-icons/im";

import Modal from "../UI/Modal";
import { menuClose } from "../../store/slices/popUpSlice";
import DropDownMenu from "./DropDownMenu";
import {
  aboutLinks,
  careerLinks,
  guidesLinks,
  officeLinks,
} from "../../asserts/data/footer";

import ImgLight from "../../asserts/images/day 1-1 – 1.png";
import ImgDark from "../../asserts/images/day 1-1 – 3.png";
import CloseImg from "../../asserts/images/BACK BUTTON.svg";

const Menu = (props) => {
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.popup.menu);
  const darkMode = useSelector((state) => state.darkMode.darkMode);

  return (
    <Modal isOpen={isOpen}>
      <div
        className={`w-screen h-screen 
         ${darkMode ? "glass-black text-white" : "glass-white"}
         relative
        `}
      >
        <img
          src={darkMode ? ImgDark : ImgLight}
          alt=""
          className={"absolute top-0 left-0 w-full h-full hidden lg:block"}
          style={{ zIndex: -1 }}
        />

        <div className="flex h-full overflow-y-auto flex-col items-center ">
          <div
            className="self-end mr-5 mt-4 sm:mr-20 sm:mt-16"
            onClick={() => dispatch(menuClose())}
          >
            {/* <ImCross className="w-5 h-5 sm:h-10 sm:w-10" /> */}
            <img src={CloseImg} alt="" />
          </div>

          <ul className="hidden space-y-5 pb-5 justify-evenly w-full sm:flex sm:flex-row sm:space-y-0">
            <MenuColumn
              title="ABOUT"
              links={aboutLinks}
              titleLink={"/aboutus"}
            />
            <MenuColumn
              title="CAREERS"
              links={careerLinks}
              titleLink={"/careers"}
            />
            <MenuColumn
              title="CSR ACTIVITY"
              links={careerLinks}
              titleLink={"/csr-activities"}
            />
            <MenuColumn title="OFFICE" links={officeLinks} isOuterLink={true} />
          </ul>

          <div className="self-start pl-10 w-full sm:hidden">
            <DropDownMenu
              title={"ABOUT"}
              links={aboutLinks}
              moreLink={"aboutus"}
            />
            <div className="w-full h-0.5 bg-white"></div>
            <DropDownMenu
              title={"CAREERS"}
              links={careerLinks}
              moreLink={"careers"}
            />
            <div className="w-full h-0.5 bg-white"></div>
            <DropDownMenu
              title="CSR ACTIVITY"
              links={guidesLinks}
              moreLink={"csr-activities"}
            />
            <div className="w-full h-0.5 bg-white"></div>
            <DropDownMenu
              title="OFFICE"
              links={officeLinks}
              isOuterLink={true}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const MenuColumn = (props) => {
  const { title, links, titleLink, isOuterLink } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const handleMenu = () => {
    if (titleLink) {
      history.push(titleLink);
      dispatch(menuClose());
    }
  };

  return (
    <ul className="flex flex-col space-y-3 pl-3 sm:space-y-16">
      <li
        className="text-xl font-bold cursor-pointer sm:text-2xl"
        onClick={handleMenu}
      >
        {title}
      </li>
      {links.map((l) => {
        return isOuterLink ? (
          <a href={l.link} key={l.id} className="text-md sm:text-xl">
            {l.name}
          </a>
        ) : (
          <li
            key={l.id}
            className="text-xl cursor-pointer"
            onClick={() => history.push("/")}
          >
            {l.name}
          </li>
        );
      })}
    </ul>
  );
};

export default Menu;
