import Modal from "./UI/Modal";
import { FiClock } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import { useSelector, useDispatch } from "react-redux";
import { jobInfoClose } from "../store/slices/popUpSlice";

const JobDescriptionPopup = (props) => {
  const dispatch = useDispatch();

  const popupStatus = useSelector((state) => state.popup.jobInfo.status);
  const description = useSelector(
    (state) => state.popup.jobInfo.description?.payload
  );

  return (
    <Modal isOpen={popupStatus} parentClases={"grid place-items-center"}>
      <div
        className="p-10 text-white flex flex-col justify-between relative rcheck job-description-card m-auto"
        style={{
          backgroundColor: "#0089FF",
        }}
      >
        <div
          className="self-end cursor-pointer"
          onClick={() => dispatch(jobInfoClose())}
        >
          <ImCross />
        </div>

        <div className="flex flex-col space-y-3">
          <h1 className="text-2xl sm:text-4xl">{description?.role}</h1>
          <div className="w-full h-0.5 bg-white"></div>
        </div>

        <div className="flex justify-center h-full pt-5 space-x-3">
          <div className="flex flex-col space-y-2 w-11/12">
            <h1>Responsibilities</h1>
            <p className="font-thin line-clamp-6">{description?.duties}</p>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row items-center justify-between text-sm sm:text-2xl opacity-60">
          <h1>{description?.location}</h1>
          <h1 className="flex items-center space-x-2">
            <FiClock /> <span>{description?.date}</span>
          </h1>
        </div>
      </div>
    </Modal>
  );
};

export default JobDescriptionPopup;
