import Loader from "../components/UI/Loader2";
import { useGetCsrActivityDetailsQuery } from "../store/api/restApi";
import Footer from "../components/Footer/Footer";
import { useSelector } from "react-redux";

const CsrActivity = (props) => {
  const slug = props.match.params.activity;

  const { data, isLoading } = useGetCsrActivityDetailsQuery(slug);

  const darkMode = useSelector((state) => state.darkMode.darkMode);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="dark:text-white dark:bg-black">
      <div
        className="min-h-screen relative bg-gray-500 dark:text-white"
        style={{
          backgroundImage: darkMode
            ? `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${data?.image})`
            : `url(${data?.image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {/* <img
          src={data?.image}
          alt=""
          className="h-full w-full object-cover absolute"
          
        /> */}
        <div
          className="absolute bottom-20 flex w-full flex-col space-y-5 md:space-y-0 md:flex-row justify-start
         items-center pl-20"
        >
          <h1 className="text-4xl md:text-8xl font-fino">{data?.title}</h1>
          {/* <Button name="Refer" color="orange" styles={"text-xl md:px-20"} /> */}
        </div>
      </div>

      <div className="w-3/4 m-auto py-20 flex flex-col items-center space-y-10">
        <h1 className="text-center text-2xl font-semibold tracking-wide">
          CSR ACTIVITY:
        </h1>

        <div className="flex flex-col w-full">
          <h1 className="text-2xl tracking-widest">1. Description</h1>
          <hr className="my-3 border-black dark:border-white" />
          <p className="text-left text-lg tracking-widest font-light w-full max-w-lg self-end">
            {data?.description}
          </p>
        </div>

        <div className="flex flex-col w-full">
          <h1 className="text-2xl tracking-widest">2. Address</h1>
          <hr className="my-3 border-black dark:border-white" />
          <p className="text-left text-lg tracking-widest font-light max-w-lg self-end">
            {data?.address}
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CsrActivity;
