import Marquee from "react-fast-marquee";

const InfiniteScrollSlider = (props) => {
  const { data } = props;

  return (
    <div className="dark:bg-black  relative">
      <div className="absolute top-1/2 transform -translate-y-1/2 z-10 grid place-items-center glass-white h-full px-1 sm:px-3 rounded-r-xl">
      <h1 className="text-center sm:text-4xl uppercase my-2">Our Partners </h1>
      </div>
      <Marquee
        style={{
          backgroundImage:
            "url(https://media.istockphoto.com/photos/seamless-asphalt-road-texture-picture-id106374028?k=20&m=106374028&s=170667a&w=0&h=7cHmZiljNeL9-AXhpHHGTIX0gxOhPBA7QJ20ynIFuzY=)",
          backgroundSize: "contain",
        }}
      >
        {data.map((l, idx) => {
          return (
            <div key={idx} className="h-12 my-4 mx-10">
              <img
                src={l.logo}
                alt=""
                className="w-full h-full object-contain"
              />
            </div>
          );
        })}
      </Marquee>
    </div>

    // <div>
    //   <div
    //     class="slider grid place-items-center dark:bg-black"
    //     style={{
    //       backgroundImage:
    //         "url(https://media.istockphoto.com/photos/seamless-asphalt-road-texture-picture-id106374028?k=20&m=106374028&s=170667a&w=0&h=7cHmZiljNeL9-AXhpHHGTIX0gxOhPBA7QJ20ynIFuzY=)",
    //       backgroundSize: "contain",
    //     }}
    //   >
    //     <div class="slide-track">
    //       {data?.map((l) => {
    //         return (
    //           <div
    //             className="slide mx-10"
    //             key={l.id}
    //             style={{ height: "100px", width: "250px" }}
    //           >
    //             <img
    //               src={l.logo}
    //               alt=""
    //               width="80%"
    //               className="object-contain w-full h-full mx-10"
    //             />
    //           </div>
    //         );
    //       })}
    //     </div>
    //   </div>
    // </div>
  );
};

export default InfiniteScrollSlider;
