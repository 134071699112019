import { createPortal } from "react-dom";

const Modal = (props) => {
  const { isOpen, children, childrenClasses, parentClases } = props;

  if (isOpen) {
    return createPortal(
      <div
        className={`fixed z-20 top-0 right-0 w-screen h-screen glass-black ${parentClases}`}
      >
        <div className={childrenClasses}>{children}</div>
      </div>,
      document.getElementById("modal")
    );
  } else {
    return null;
  }
};

export default Modal;
