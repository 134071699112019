import { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";

import Header from "./components/Header/Header";
import Menu from "./components/Header/Menu";
import ApplyPopup from "./components/ApplyPopup";
import ScrolltoTop from "./components/ScrolltoTop";
import { activateDarkMode } from "./store/slices/darkmodeSlice";
// import Loader from "./components/UI/Loader";
import Loader from "./components/UI/Loader2.js";

// css
import "react-toastify/dist/ReactToastify.css";
import JobDescriptionPopup from "./components/JobDescriptionPopup";
import CsrActivities from "./pages/CsrActivities";
import CsrActivity from "./pages/CsrActivity";
import Footer from "./components/Footer/Footer";
import { InfiniteCarousel } from "react-leaf-carousel";
import InfiniteScrollSlider from "./components/InfiniteScrollSlider";
import AppBanner from "./components/AppBanner";
import EmployeeInfo from "./pages/EmployeeInfo";

// pages
const Homepage = lazy(() => import("./pages/Homepage"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const Careers = lazy(() => import("./pages/Careers"));
const ProjectDetails = lazy(() => import("./pages/ProjectDetails"));

const App = () => {
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const dispatch = useDispatch();

  useEffect(() => {
    const mode = JSON.parse(localStorage.getItem("darkMode"));

    if (mode) {
      dispatch(activateDarkMode());
    }
  }, []);

  return (
    <div className={`${darkMode && "dark"} font-poppins`}>
      <Suspense fallback={<Loader />}>
        <Router>
          {/* All Popups */}
          <Menu />
          <ApplyPopup />
          <ToastContainer theme={darkMode ? "dark" : "light"} />
          <JobDescriptionPopup />
          {/* All Popups */}

          <ScrolltoTop />

          <Header />
          <Switch>
            <Route path="/" exact component={Homepage} />
            <Route path="/aboutus" exact component={AboutUs} />
            <Route path="/careers" exact component={Careers} />
            <Route path="/dev" exact component={Loader} />
            <Route path="/csr-activities" exact component={CsrActivities} />
            <Route
              path="/csr-activities/:activity"
              exact
              component={CsrActivity}
            />
            <Route path="/projects/:project" exact component={ProjectDetails} />
            <Route path="/employeeinfo/:id" exact component={EmployeeInfo} />
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
};

export default App;
