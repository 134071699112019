import Footer from "../components/Footer/Footer";
import { FaRoad, FaPaperPlane } from "react-icons/fa";
import { MdPeopleAlt, MdPhoneInTalk } from "react-icons/md";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import { useGetEmployeeDetailsQuery } from "../store/api/restApi";

const EmployeeInfo = (props) => {
  console.log("pat: ", props.location.pathname);

  // const qrLink = `https://srkprojects.com${props.location.pathname}.com`;

  const qrCodeLink = props.location.pathname;

  const { id } = useParams();

  const { data: employeeData } = useGetEmployeeDetailsQuery({ slug: id });

  return (
    <>
      <div className="pl-4 sm:pl-16 pb-12 pr-4 sm:pr-20 pt-36 font-poppins flex flex-col-reverse lg:flex lg:flex-row dark:bg-ownDarkBlue dark:text-white">
        {/* //Left */}
        <div className=" pt-12 lg:pt-0 w-full lg:w-4/12  grid  place-content-center lg:place-content-start">
          <div className="w-12/12  h-14 sm:h-16 py-5 pl-5  bg-ownGreen rounded-xl">
            <div className="text-white font-normal flex space-x-1  justify-items-center items-cenetr truncate">
              <p className=" ">Work Status: </p>
              <p> Working</p>
            </div>
          </div>
          {/* /// Grid Cards */}
          <div className=" pt-4 grid grid-cols-2 gap-x-4 lg:grid-cols-1 xl:grid-cols-2 xl:gap-x-4 gap-y-4 place-content-center ">
            <GridContainer
              title={"Attendance"}
              subtitle={"Present"}
              stat={`${employeeData?.attendence} Days`}
              color={"#027CDC"}
            />
            <GridContainer
              title={"Projects Completed"}
              subtitle={"Projects"}
              stat={`${employeeData?.projects_completed}`}
              color={"#67A764E8"}
            />
            <GridContainer
              title={"Punctuality"}
              subtitle={"Punctual"}
              stat={employeeData?.punctuality}
              color={"#8B73FF"}
            />
            <GridContainer
              title={"Behaviour"}
              subtitle={"Behaviour"}
              stat={employeeData?.behaviour}
              color={"#2835A0"}
            />
          </div>
          {/* stats */}
          <h1 className="pt-2 text-base font-medium text-ownGrey2 dark:text-white">
            Statistics
          </h1>

          <div className="pt-8 grid place-content-center grid-cols-2 gap-x-8 gap-y-6">
            {employeeData?.statistics.map((statistic) => {
              return (
                <StatisticsData
                  title={statistic.title}
                  stat={statistic.statistics}
                  key={statistic.id}
                />
              );
            })}
          </div>
        </div>
        {/* right */}
        <div className="w-full xl:w-9/12 2xl:w-8/12 sm:pl-4 justify-items-start">
          <div className="flex border-l-0 border-gray-500 border-2 border-y-neutral-500">
            <div className="hidden sm:block w-12 border-l-4 border-ownBlue5 h-40 bg-ownBlue6"></div>
            <div className="flex  space-x-8 justify-between flex-auto items-center mr-1 sm:mr-12">
              <div className="flex items-center space-x-4 sm:space-x-8 sm:space-x-8">
                <div>
                  <img
                    className="h-32  sm:h-40 object-cover"
                    src={employeeData?.image}
                    alt=""
                  />
                </div>
                <div className="flex flex-col">
                  <h1 className="font-medium text-base sm:text-2xl xl:text-4xl">
                    {employeeData?.name}
                  </h1>
                  <p className="font-light text-sm sm:text-lg xl:text-2xl">
                    {employeeData?.role}
                  </p>
                </div>
              </div>
              {/* <div>
                <img
                  className="w-24 h-24 sm:mr-14 object-cover"
                  src="https://media.istockphoto.com/vectors/big-sale-bar-codes-all-data-is-fictional-eps-8-vector-id1246002729?k=20&m=1246002729&s=612x612&w=0&h=uh0Qm_xQUdY_evfEpM0M7iN64DylFc-5XgSufukyJ5g="
                  alt=""
                />
              </div> */}
              <div className="w-24 h-24  object-cover">
                <QRCode
                  value={`https://srkprojects.com${qrCodeLink}`}
                  size={96}
                />
              </div>
            </div>
          </div>
          {/* // Bio */}
          <div className="pt-11 sm:border-l-2 sm:border-ownGrey1 sm:pl-6">
            <div className="w-fit sm:w-11/12">
              <h1 className="font-semibold text-lg sm:text-2xl lg:text-4xl">
                Bio
              </h1>
              <p className="pt-3 sm:pt-7 text-xs sm:text-lg lg:text-xl font-light">
                {employeeData?.bio}
              </p>
            </div>
            <div className="font-semibold text-lg sm:text-2xl lg:text-4xl pt-7">
              <h1 className="font-semibold text-lg sm:text-2xl lg:text-4xl">
                Experience Skill
              </h1>
              <p className="pt-3 sm:pt-7 text-xs sm:text-lg lg:text-xl font-light">
                {employeeData?.experience_text}
              </p>
            </div>

            <div className="grid grid-cols-3  sm:grid-flow-row sm:flex pt-11  sm:gap-x-12">
              {employeeData?.read_skills.map((skill) => {
                return <IconsWithTitle icon={skill.icon} title={skill.title} />;
              })}
            </div>
          </div>
          {/* //bio-end */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EmployeeInfo;

const IconsWithTitle = (props) => {
  const { icon, title } = props;
  return (
    <div className="flex flex-col items-center space-y-2">
      <div>
        <img
          src={`${process.env.REACT_APP_IMG_BASEURL}media/${icon}`}
          alt=""
          style={{ maxHeight: "60px" }}
        />
      </div>

      <p className="font-medium text-xs text-ownBlack dark:text-white">
        {title}
      </p>
    </div>
  );
};

const GridContainer = (props) => {
  const { title, subtitle, stat, color } = props;

  return (
    <div
      className={`rounded-2xl w-32 sm:w-44 h-56 sm:h-60 grid grid-cols-1  justify-around text-white `}
      style={{ backgroundColor: color }}
    >
      <div className="grid gap-y-32 mb-4">
        <h1 className="pt-4 pl-4 font-medium text-sm sm:text-base align-text-top flex-wrap">
          {title}
        </h1>

        <div className="flex flex-col items-start pl-4">
          <p className=" font-medium text-xs sm:text-base">{stat}</p>
          <p className="pb-4 font-normal text-xs sm:text-base">{subtitle}</p>
        </div>
      </div>
    </div>
  );
};
const StatisticsData = (props) => {
  const { stat, title } = props;

  return (
    <div className="font-medium justify-items-start flex flex-col space-y-2">
      <h1 className=" text-3xl">{stat}</h1>
      <p
        className="text-xs "
        // style={{ textDecorationColor: underlineColor }} underline underline-offset-4
      >
        {title}
      </p>
    </div>
  );
};
