import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

// reducers
import darkModeReducer from "./slices/darkmodeSlice";
import popupReducer from "./slices/popUpSlice";
import applyAutoFillReducer from "./slices/applyAutoFillSlice";

// apis
import restApi from "./api/restApi";

const store = configureStore({
  reducer: {
    darkMode: darkModeReducer,
    popup: popupReducer,
    applyAutoFill: applyAutoFillReducer,
    [restApi.reducerPath]: restApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(restApi.middleware),
});

setupListeners(store.dispatch);

export default store;
