const GradientButton = (props) => {
  const { name, ...btn } = props;

  return (
    <button
      className="bg-gradient-to-r from-ownBrown to-ownBlue px-5 rounded-md py-0.5 text-white"
      {...btn}
    >
      {name}
    </button>
  );
};

export default GradientButton;
