import { useSelector } from "react-redux";

import Modal from "./UI/Modal";
import ApplyForm from "./ApplyForm";

const ApplyPopup = (props) => {
  const isOpen = useSelector((state) => state.popup.apply);
  const formAutoFill = useSelector((state) => state.applyAutoFill);

  return (
    <Modal isOpen={isOpen} parentClases="grid place-items-center">
      <ApplyForm color="glass-white" showClose={true} data={formAutoFill} />
    </Modal>
  );
};

export default ApplyPopup;
