import Loader from "../components/UI/Loader2";
import { useGetCsrActivityQuery } from "../store/api/restApi";
import GradientButton from "../components/UI/GradientButton";
import Img from "../asserts/images/csr logo.svg";
import Footer from "../components/Footer/Footer";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Logo from "../asserts/images/Mask Group 4.png";

const CsrActivities = (props) => {
  const { data, isLoading } = useGetCsrActivityQuery();

  const darkMode = useSelector((state) => state.darkMode.darkMode);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div
        className="h-screen grid place-items-end"
        style={{
          backgroundImage: darkMode
            ? "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(https://images.squarespace-cdn.com/content/v1/53ed1999e4b0f5d3db2825af/1497532766133-PKO6JFWITGL3SU7NBNUX/how+small+busineses+can+engage+in+corporate+social+responsibility.jpg?format=1500w)"
            : "url(https://images.squarespace-cdn.com/content/v1/53ed1999e4b0f5d3db2825af/1497532766133-PKO6JFWITGL3SU7NBNUX/how+small+busineses+can+engage+in+corporate+social+responsibility.jpg?format=1500w)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="glass-white max-w-max rounded-tl-2xl">
          <img
            src={Logo}
            alt=""
            // className="h-full w-full object-cover"
          />
        </div>
      </div>

      {data?.map((i, idx) => {
        return (
          <InfoCard
            key={i.id}
            data={i}
            reverse={idx % 2 === 0 ? true : false}
            number={idx + 1}
          />
        );
      })}
      <Footer />
    </div>
  );
};

const InfoCard = (props) => {
  const { reverse, data, number } = props;

  const history = useHistory();

  return (
    <div
      className={`relative bg-lightPurple xl:flex dark:bg-ownBlack ${
        reverse && "flex-row-reverse"
      }`}
      style={{ height: "699px" }}
    >
      <div className="h-full w-full xl:w-1/2">
        <img src={data?.image} alt="" className="h-full w-full object-cover" />
      </div>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 xl:transform-none xl:static xl:grid xl:place-items-center xl:w-1/2 ">
        <div className="rounded-3xl flex flex-col items-center shadow-2xl p-10 bg-white dark:bg-ownBrown2 dark:text-white aboutus-info-card">
          <h1 className="text-3xl font-semibold self-start w-full flex justify-between">
            {number}. {data?.title}{" "}
            <span>
              <img src={Img} alt="" />
            </span>
          </h1>
          <div className="border-t-2 border-black w-full"></div>
          <p className="pt-5 pb-10">{data?.description}</p>

          <GradientButton
            name="Details"
            onClick={() => history.push(`/csr-activities/${data?.slug}`)}
          />
        </div>
      </div>
    </div>
  );
};

export default CsrActivities;
