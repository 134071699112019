import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  applyTo: "",
  state: "",
};

const applyAutoFillSlice = createSlice({
  name: "applyAutoFill",
  initialState: initialState,
  reducers: {
    autoFillData(state, action) {
      state.applyTo = action.payload.applyTo;
      state.state = action.payload.state;
    },

    resetData(state, action) {
      state.applyTo = "";
      state.state = "";
    },
  },
});

export const { autoFillData, resetData } = applyAutoFillSlice.actions;

export default applyAutoFillSlice.reducer;
