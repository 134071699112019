import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FiMenu } from "react-icons/fi";

import ToggleSwitch from "./ToggleSwitch";
import { menuOpen } from "../../store/slices/popUpSlice";

import LogoBlack from "../../asserts/images/logo-2.svg";
import LogoWhite from "../../asserts/images/logo-3.svg";

const Header = (props) => {
  const dispatch = useDispatch();

  return (
    <div
      className="bg-gradient-to-b from-white fixed top-0 w-full px-10 z-10 dark:from-black"
      style={{ height: "188px" }}
    >
      <div className="flex justify-between mt-5">
        <Link to="/">
          <img src={LogoBlack} alt="" className="h-12 dark:hidden" />
          <img src={LogoWhite} alt="" className="h-12 hidden dark:block" />
        </Link>

        <div className="flex space-x-5 items-center">
          <ToggleSwitch />
          <div onClick={() => dispatch(menuOpen())}>
            <FiMenu className="text-black dark:text-white" size="38" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
