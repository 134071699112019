import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  darkMode: false,
};

const darkModeSlice = createSlice({
  name: "darkMode",
  initialState: initialState,
  reducers: {
    toggleDarkMode: (state, action) => {
      state.darkMode = !state.darkMode;
      localStorage.setItem("darkMode", state.darkMode);
    },

    activateDarkMode: (state, action) => {
      state.darkMode = true;
    },
  },
});

export const { toggleDarkMode, activateDarkMode } = darkModeSlice.actions;

export default darkModeSlice.reducer;
