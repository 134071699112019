export const aboutLinks = [
  // { id: 1, name: "Meets" },
  // { id: 2, name: "Contact" },
  // { id: 3, name: "Address" },
  // { id: 4, name: "Phone" },
];

export const careerLinks = [
  // { id: 1, name: "Civil" },
  // { id: 2, name: "Marketing" },
  // { id: 3, name: "Labour" },
  // { id: 4, name: "Office" },
  // { id: 5, name: "Management" },
];

export const guidesLinks = [
  // { id: 1, name: "Guides" },
  // { id: 2, name: "Future Growth" },
  // { id: 3, name: "Stats" },
  // { id: 4, name: "Owners" },
];

export const officeLinks = [
  {
    id: 1,
    name: "Office Login",
    link: "https://admin.srkprojects.com/accounts/login/",
  },
  {
    id: 2,
    name: "Site Login",
    link: "https://admin.srkprojects.com/web/dashboard/",
  },
  // { id: 3, name: "Manager Login", link: "admin@srkprojects.com" },
  // { id: 4, name: "Owner Login", link: "admin@srkprojects.com" },
  // { id: 5, name: "Profile Login", link: "admin@srkprojects.com" },
];
