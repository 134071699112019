import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menu: false,
  apply: false,
  jobInfo: { status: false, description: null },
};

const popupSlice = createSlice({
  name: "popup",
  initialState: initialState,
  reducers: {
    menuOpen(state, action) {
      state.menu = true;
    },

    menuClose(state, action) {
      state.menu = false;
    },

    applyOpen(state, action) {
      state.apply = true;
    },

    applyClose(state, action) {
      state.apply = false;
    },

    jobInfoOpen(state, action) {
      state.jobInfo.description = action.payload;
      state.jobInfo.status = true;
    },

    jobInfoClose(state, action) {
      state.jobInfo.status = false;
      state.jobInfo.description = null;
    },
  },
});

export const {
  menuOpen,
  menuClose,
  applyOpen,
  applyClose,
  jobInfoOpen,
  jobInfoClose,
} = popupSlice.actions;

export default popupSlice.reducer;
