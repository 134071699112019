import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import Map from "../../asserts/images/india.svg";

import {
  aboutLinks,
  careerLinks,
  guidesLinks,
  officeLinks,
} from "../../asserts/data/footer";
import DropDownMenu from "../Header/DropDownMenu";
import ImgLight from "../../asserts/images/Group 560.jpg";
import ImgDark from "../../asserts/images/Group 561.jpg";

import LogoWhite from "../../asserts/images/logo-3.svg";
import LogoDark from "../../asserts/images/logo-2.svg";
import InfiniteScrollSlider from "../InfiniteScrollSlider";

import { ourEmployees } from "../../asserts/data/logosData";

const Footer = (props) => {
  const { containerClases } = props;

  const darkMode = useSelector((state) => state.darkMode.darkMode);

  // ${darkMode ? "glass-black" : "glass-white"}

  return (
    <>
      <InfiniteScrollSlider data={ourEmployees} />
      <div
        className={`flex flex-col space-y-10 w-full items-center justify-evenly py-8 z-10 lg:space-y-0 lg:flex-row dark:text-white relative
      ${containerClases}`}
        style={{ zIndex: 0 }}
      >
        <img
          src={darkMode ? ImgDark : ImgLight}
          alt=""
          className="absolute top-0 left-0 w-full h-full"
          style={{ zIndex: 0 }}
        />

        <div
          className="flex flex-col items-center space-y-10 lg:hidden"
          style={{ zIndex: 1 }}
        >
          <div>
            <img src={LogoDark} alt="" className="h-12 dark:hidden" />
            <img
              src={LogoWhite}
              alt=""
              className="h-12 hidden dark:inline-block"
            />
          </div>

          <div className="flex justify-evenly space-x-14 sm:space-x-20">
            <FaFacebook className="dark:text-white" size="28px" />
            <FiInstagram className="dark:text-white" size="28px" />
            <FaLinkedin className="dark:text-white" size="28px" />
            <FaYoutube className="dark:text-white" size="28px" />
          </div>
        </div>

        <div
          className=" lg:flex-col items-center space-x-20 lg:space-x-0 justify-evenly space-y-10 hidden lg:flex"
          style={{ zIndex: 1 }}
        >
          <div className="">
            <img src={LogoDark} alt="" className="h-12 dark:hidden" />
            <img
              src={LogoWhite}
              alt=""
              className="h-12 hidden dark:inline-block"
            />
          </div>
          <FaFacebook className="dark:text-white" size="28px" />
          <FiInstagram className="dark:text-white" size="28px" />
          <FaLinkedin className="dark:text-white" size="28px" />
          <FaYoutube className="dark:text-white" size="28px" />
        </div>
        <div className="flex flex-col space-y-7" style={{ zIndex: 1 }}>
          <div className="grid grid-cols-2 place-items-center gap-2 sm:flex justify-between sm:space-x-10 text-xl">
            <Link to="/aboutus">
              <p>ABOUT</p>
            </Link>
            <Link to="/careers">
              <p>CAREER</p>
            </Link>
            <Link to="/csr-activities">
              <p>CSR ACTIVITY</p>
            </Link>
            {/* <a href="https:'"> */}
            <p>OFFICE</p>
            {/* </a> */}
          </div>

          <h1 className="text-5xl">Our Presence</h1>

          <div className="flex text-lg sm:text-3xl space-x-4 px-3 justify-between">
            <div className="flex flex-col space-y-3">
              <p>Telangana</p>
              <p>Maharashtra</p>
              <p>Karnataka</p>
              <p>Mizoram</p>
            </div>

            <div className="flex flex-col space-y-3">
              <p>Andhra Pradesh</p>
              <p>Gujarat</p>
              <p>Arunachal Pradesh</p>
              <p>Chhattisgarh</p>
            </div>
          </div>
        </div>
        <div style={{ zIndex: 1 }}>
          <img src={Map} alt="" />
        </div>
      </div>
    </>
  );
};

export default Footer;
