import Img1 from "../images/image40.gif";
import Img2 from "../images/image43.png";
import Img3 from "../images/image44.png";
import Img4 from "../images/image45.jpeg";
import Img5 from "../images/image64.png";
import Img6 from "../images/image65.jpeg";
import Img7 from "../images/image74.png";
import Img8 from "../images/image75.png";
import Img9 from "../images/Picture3.jpg";
import Img10 from "../images/Picture4.png";
import Img11 from "../images/Picture5.jpg";
import Img12 from "../images/Picture6.jpg";
import Img13 from "../images/Picture7.png";
import Img14 from "../images/Picture8.gif";
import Img15 from "../images/Picture9.gif";
import Img16 from "../images/Picture10.png";
import Img17 from "../images/Picture11.jpg";
import Img18 from "../images/Picture12.png";
import Img19 from "../images/Picture13.jpg";
import Img20 from "../images/image67.png";
import Img21 from "../images/image68.gif";
import Img22 from "../images/image69.png";
import Img23 from "../images/image70.jpeg";
import Img24 from "../images/image73.gif";
import Img25 from "../images/image31.png";
import Img26 from "../images/image32.jpeg";
import Img27 from "../images/sbi.png";

export const ourEmployees = [
  { id: 1, logo: Img1 },
  { id: 2, logo: Img2 },
  { id: 3, logo: Img3 },
  { id: 4, logo: Img4 },
  { id: 5, logo: Img5 },
  { id: 6, logo: Img6 },
  { id: 7, logo: Img7 },
  { id: 8, logo: Img8 },
  { id: 9, logo: Img9 },
  { id: 10, logo: Img10 },
  { id: 11, logo: Img11 },
  { id: 12, logo: Img12 },
  { id: 13, logo: Img13 },
  { id: 14, logo: Img14 },
  { id: 15, logo: Img15 },
  { id: 16, logo: Img16 },
  { id: 17, logo: Img17 },
  { id: 18, logo: Img18 },
  { id: 19, logo: Img19 },
  { id: 20, logo: Img20 },
  { id: 21, logo: Img21 },
  { id: 22, logo: Img22 },
  { id: 23, logo: Img23 },
  { id: 24, logo: Img24 },
  { id: 25, logo: Img25 },
  { id: 26, logo: Img26 },
  { id: 27, logo: Img27 },
];
