import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const restApi = createApi({
  reducerPath: "restApi",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASEURL,
  }),

  endpoints: (builder) => ({
    //* HomePage
    getProjects: builder.query({
      query: () => "web/api/client/v1/project-limited/",
    }),

    getTestimonials: builder.query({
      query: () => "web/api/client/v1/about-us-profile/",
    }),

    getSignatureProjects: builder.query({
      query: (stage) => `web/api/client/v1/signature-projects/${stage}/`,
    }),

    getAboutProfile: builder.query({
      query: () => "web/api/client/v1/about-us-profile/lalith-reddy/",
    }),

    //* About Us
    getOurTeam: builder.query({
      query: () => "web/api/client/v1/about-us-profile/",
    }),

    getCsrActivity: builder.query({
      query: () => "web/api/client/v1/csr-activity/",
    }),

    getCsrActivityDetails: builder.query({
      query: (activity) => `web/api/client/v1/csr-activity/${activity}/`,
    }),

    //* Careers
    getAllCareers: builder.query({
      query: () => "web/api/client/v1/career/",
    }),

    getGallaryDetails: builder.query({
      query: () => "web/api/client/v1/company-gallery/civil-departiment/",
    }),

    getGallary: builder.query({
      query: () => "web/api/client/v1/company-gallery/",
    }),

    //* project details
    getOtherProjects: builder.query({
      query: (project) => `web/api/client/v1/other-projects/${project}/`,
    }),

    getProjectTestimonials: builder.query({
      query: (project) => `web/api/client/v1/project-testimonials/${project}/`,
    }),

    getProjectGallary: builder.query({
      query: (project) => `web/api/client/v1/project-gallery/${project}/`,
    }),

    getProjectStats: builder.query({
      query: (project) => `web/api/client/v1/project-stats/${project}/`,
    }),

    getProjectDetails: builder.query({
      query: (project) => `web/api/client/v1/projects/${project}/`,
    }),

    //* common
    getRandomCareer: builder.query({
      query: () => "web/api/client/v1/career-random/",
    }),

    postContactus: builder.mutation({
      query: (body) => ({
        url: "web/api/client/v1/contact-us/",
        method: "POST",
        body,
      }),
    }),

    postApply: builder.mutation({
      query: (body) => ({
        url: "web/api/client/v1/career-apply/",
        method: "POST",
        body,
      }),
    }),

    // * support
    getStates: builder.query({
      query: () => "web/api/client/v1/state/",
    }),

    getCareers: builder.query({
      query: () => "web/api/client/v1/career-support/",
    }),
    // * Employee Details
    getEmployeeDetails: builder.query({
      query: (data) =>
        `custom-utils/api/client/v1/about-us-profile-employees/${data.slug}/`,
    }),
  }),
});

export const {
  usePostContactusMutation,
  useGetProjectsQuery,
  useGetTestimonialsQuery,
  useGetRandomCareerQuery,
  useGetCsrActivityQuery,
  useGetOurTeamQuery,
  useGetAllCareersQuery,
  useGetGallaryQuery,
  useGetProjectDetailsQuery,
  useGetOtherProjectsQuery,
  useGetProjectGallaryQuery,
  useGetProjectTestimonialsQuery,
  usePostApplyMutation,
  useGetStatesQuery,
  useGetCareersQuery,
  useGetSignatureProjectsQuery,
  useGetProjectStatsQuery,
  useGetCsrActivityDetailsQuery,
  useGetEmployeeDetailsQuery,
} = restApi;
export default restApi;
