import { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FiChevronDown } from "react-icons/fi";
import { ImCross } from "react-icons/im";

import { applyClose } from "../store/slices/popUpSlice";
import { resetData } from "../store/slices/applyAutoFillSlice";
import {
  usePostApplyMutation,
  useGetCareersQuery,
  useGetStatesQuery,
} from "../store/api/restApi";

const ApplyForm = (props) => {
  const { data, showClose, containerClases, color } = props;

  const [file, setFile] = useState(null);

  const dispatch = useDispatch();

  const [postApply, { data: postApplyData }] = usePostApplyMutation();
  const { data: careersData } = useGetCareersQuery();
  const { data: statesData } = useGetStatesQuery();

  useEffect(() => {
    if (postApplyData) {
      toast(
        `Hey! ${postApplyData.name} we have received your application and will get back to you soon`
      );
    }
  }, [postApplyData]);

  const initialValues = {
    name: "",
    phone: "",
    email: "",
    applyTo: data?.applyTo ? data.applyTo : "",
    state: "",
  };

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (values) => {
    const { name, phone, email, applyTo, state } = values;

    const formData = new FormData();
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("career_applying", applyTo);
    formData.append("state", state);
    formData.append("resume", file, file.name);

    postApply(formData);
  };

  const handleClose = () => {
    dispatch(resetData());
    dispatch(applyClose());
  };

  return (
    <div
      className={`p-5 rounded-3xl opacity-100 relative m-auto apply-form sm:p-14 ${containerClases} ${
        color ? color : "bg-gradient-to-br from-ownBlue2 to-ownBlue4"
      }`}
    >
      {showClose && (
        <div
          className="absolute top-3 right-3 sm:top-10 sm:right-10"
          onClick={handleClose}
        >
          <ImCross color="white" />
        </div>
      )}
      <div className="flex flex-col space-y-0 text-white pb-3">
        <h3 className="text-base">YOU HAVE CHOSEN A WISE DECISION</h3>
        <div className="h-1 border-t-2 border-white"></div>
        <p className="text-xs">
          Please Check the information Before submit it can't be edited once
          Submitted.
        </p>
      </div>

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form className="flex flex-col font-semibold space-y-5 lg:flex-row lg:space-y-0 lg:space-x-10">
          <div className="flex flex-col items-center space-y-5">
            <div className="w-full sm:w-80">
              <Field
                type="text"
                name="name"
                className="w-full h-12 rounded-md px-2 text-black"
                placeholder="Name"
              />
            </div>

            <div className="w-full flex rounded-md overflow-hidden sm:w-80">
              <div className="text-lg px-3 grid place-items-center form-button-gradient">
                +91
              </div>
              <Field
                type="number"
                name="phone"
                className="w-full h-12 px-2"
                placeholder="Phone"
              />
            </div>

            <div className="w-full h-12 relative rounded-md overflow-hidden sm:w-80">
              <Field as="select" name="applyTo" className="w-full h-full">
                <option value="" disabled>
                  Apply To
                </option>

                {careersData?.map((i) => {
                  return (
                    <option key={i.id} value={i.id}>
                      {i.title}
                    </option>
                  );
                })}
              </Field>
              <div className="absolute right-0 top-0 form-button-gradient w-14 h-full grid place-items-center">
                <FiChevronDown size="30px" />
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center space-y-5">
            <div className="w-full rounded-md overflow-hidden h-12 flex sm:w-80">
              <div className="w-1/2 form-button-gradient grid place-items-center">
                Choose CV File
              </div>
              <label
                htmlFor="cv"
                className="w-1/2 bg-white h-full grid place-items-center"
              >
                {file ? file.name : "No File Chosen"}
              </label>
              <input
                name="cv"
                id="cv"
                type="file"
                className="invisible w-0"
                onChange={handleFileUpload}
              />
            </div>

            <div className="w-full sm:w-80">
              <Field
                type="email"
                name="email"
                className="w-full h-12 rounded-md"
                placeholder="Email"
              />
            </div>

            <div className="w-full h-12 relative bg-white rounded-md overflow-hidden sm:w-80">
              <div className="absolute right-0 top-0 form-button-gradient w-14 h-full grid place-items-center">
                <FiChevronDown size="30px" />
              </div>
              <Field as="select" name="state" className="w-full h-full">
                <option value="" disabled>
                  State
                </option>
                {statesData?.map((i) => {
                  return (
                    <option key={i.id} value={i.id}>
                      {i.states}
                    </option>
                  );
                })}
              </Field>
            </div>

            <button
              type="submit"
              className="px-20 bg-white rounded-xl down-shadow py-1 font-bold lg:self-end"
            >
              Submit
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ApplyForm;
