import { Disclosure } from "@headlessui/react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { menuClose } from "../../store/slices/popUpSlice";

const DropDownMenu = (props) => {
  const { title, links, moreLink, isOuterLink } = props;

  const dispatch = useDispatch();

  return (
    <Disclosure>
      <Disclosure.Button className="py-2 flex dark:text-white">
        {title}{" "}
        <span>
          <RiArrowDropDownLine size="20px" />
        </span>
      </Disclosure.Button>

      {links?.map((l, idx) => {
        return (
          <Disclosure.Panel
            key={l.id}
            className="uppercase mb-2 dark:text-white"
          >
            {isOuterLink ? <a href={l.link}>{l.name}</a> : l.name}
          </Disclosure.Panel>
        );
      })}

      <Disclosure.Panel onClick={() => dispatch(menuClose())}>
        <Link to={moreLink ? `/${moreLink}` : "/"} className="cursor-pointer">
          MORE
        </Link>
      </Disclosure.Panel>
    </Disclosure>
  );
};

export default DropDownMenu;
