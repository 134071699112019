import Blob from "../asserts/images/blob.svg";
import ButtonImg from "../asserts/images/google-play-badge.png";
import LocKImgBlack from "../asserts/images/Group 567 (1).svg";
import LockImgWhite from "../asserts/images/Group 569 (1).svg";
import TickImgBlack from "../asserts/images/Group 568 (1).svg";
import TickImgWhite from "../asserts/images/Group 571 (1).svg";
import MobileImg from "../asserts/images/Group 11.svg";
import { useSelector } from "react-redux";

const AppBanner = (props) => {
  const darkMode = useSelector((state) => state.darkMode.darkMode);

  return (
    <div className="min-h-screen flex flex-col xl:flex-row items-center justify-evenly xl:pr-24 dark:bg-black dark:text-white">
      <div
        className="relative grid place-items-center sm:px-36"
        style={{
          backgroundImage: `url(${Blob})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <img src={MobileImg} alt="" style={{ maxWidth: "200px" }} />
      </div>

      <div className="h-full flex flex-col items-center text-center justify-evenly space-y-10 xl:space-y-20 xl:mr-10">
        <div className="text-center text-2xl sm:text-3xl font-semibold">
          <h1>DOWNLOAD SRK ERP</h1>
          <h1>(SMART AND SECURED APP)</h1>
        </div>

        <p style={{ maxWidth: "487PX" }} className="text-xl font-light px-2">
          Unlock the potential of seamless and efficient enterprise resource
          planning with SRK ERP, the smart solution tailored for modern business
          needs. Designed for SRK Constructions and Projects Private Limited,
          this robust app integrates all facets of operations, from project
          management to finance, HR, and beyond, ensuring a secure and
          streamlined workflow. Enhance decision-making with real-time data
          insights and foster collaboration across departments with our
          user-friendly interface. Embrace the power of SRK ERP and drive your
          business towards operational excellence and growth.
        </p>
        <a href="">
          <img src={ButtonImg} alt="" className="w-40" />
        </a>
      </div>

      <div className="flex xl:flex-col items-center justify-evenly w-full xl:w-auto xl:space-y-20 mt-4 xl:mt-0">
        {!darkMode ? (
          <>
            <div className="flex flex-col items-center ">
              <img src={LocKImgBlack} alt="" />
              <p>SECURE</p>
            </div>
            <div className="flex flex-col items-center ">
              <img src={TickImgBlack} alt="" />
              <p>SAFE</p>
            </div>
          </>
        ) : (
          <>
            <div className="flex-col items-center flex">
              <img src={LockImgWhite} alt="" />
              <p>SECURE</p>
            </div>
            <div className="flex-col items-center flex">
              <img src={TickImgWhite} alt="" />
              <p>SAFE</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AppBanner;
